.app_navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 3px 10px -10px black;
    padding: 1rem 12rem;
}

.app_navbar-logo {
    margin: 0 1.5rem;
    cursor: pointer;
}

.app_navbar-logo img {
    width: 35px;
}

.app_navbar-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
}

.app_navbar-links li {
    margin: 0 1.5rem;
    cursor: pointer;
    font-weight: 500;
}

.app_navbar-links li .active {
    border-bottom: 3px solid #000;
}

.app_navbar-smallscreen {
    display: none;
}

.app_navbar-smallscreen-links {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app_navbar-smallscreen-links li {
    margin: 1.2rem;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    -webkit-animation: overlay__cls 0.5s ease;
    -moz-animation: overlay__cls 0.5s ease;
    -o-animation: overlay__cls 0.5s ease;
    animation: overlay__cls 0.5s ease;
}

@keyframes overlay__cls {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to { opacity: 1 }
}

.app_navbar-smallscreen-links li .active {
    border-bottom: 3px solid #000;
}

.app_navbar-smallscreen-menu {
    font-size: 25px;
    cursor: pointer;
}

.app_navbar-smallscreen-close {
    font-size: 28px;
    cursor: pointer;
    position: absolute;
    top: 17px;
    right: 192px;
}

.app_navbar-smallscreen-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    flex-direction: column;
    z-index: 5;
    -webkit-animation: color-overlay 0.3s ease;
    -moz-animation: color-overlay 0.3s ease;
    -o-animation: color-overlay 0.3s ease;
    animation: color-overlay 0.3s ease;
}

@keyframes color-overlay {
    0%   { opacity: 0; }
    100% { opacity: 1; }
    }
@-webkit-keyframes color-overlay {
    0%   { opacity: 0; }
    100% { opacity: 1; }
    }
@-moz-keyframes color-overlay {
    0%   { opacity: 0; }
    100% { opacity: 1; }
    }
@-o-keyframes color-overlay {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}


@media screen and (max-width: 576px) {

}

@media screen and (max-width: 768px) {
    
}

@media screen and (max-width: 992px) {
    .app_navbar-links {
        display: none;
    }
    .app_navbar-smallscreen {
        display: flex;
    }
    .app_navbar-logo {
        margin: 0;
    }
    .app_navbar {
        padding: 1rem 2rem;
    }
    .app_navbar-smallscreen-close {
        top: 20px;
        right: 30px;
    }
}

@media screen and (max-width: 1200px) {

}