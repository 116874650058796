.container {
    max-width: 1100px;
    width: 95%;
    margin: 0 auto;
    padding: 1rem 0;
}

@media screen and (max-width: 576px) {
    
}

@media screen and (max-width: 768px) {
    .container {
        width: 100%;
    }
}

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 1200px) {

}