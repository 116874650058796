.page_home-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-header-p {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.5px;
}

.home-header-p-view {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.5px;
    cursor: pointer;
}

@media screen and (max-width: 576px) {
    
}

@media screen and (max-width: 768px) {
    
}

@media screen and (max-width: 992px) {
    .page_home-text {
        padding: 0 2rem;
    }
}

@media screen and (max-width: 1200px) {

}