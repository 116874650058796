.back-to-top {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    font-size: 22px;
    background: transparent;
    border: none;
    z-index: 3;
    cursor: pointer;
    color: #000;
}