.cont_about {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    row-gap: 3rem;
    padding: 5rem 2rem;
    align-items: center;
}

.cont_about img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border: 8px solid #fff;
    box-shadow: -3px 3px 12px -6px black;
}

.cont_about-content-head {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-align: left;
}

.cont_about-content-p {
    font-size: 16px;
    font-weight: 300;
    line-height: 2rem;
    letter-spacing: -0.5px;
    text-align: left;
}

.cont_about-content-social {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    font-size: 24px;
}

.cont_about-content-social span {
    margin-right: 1.5rem;
}

@media screen and (max-width: 576px) {
    .cont_about {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 768px) {
    
}

@media screen and (max-width: 992px) {
    
}

@media screen and (max-width: 1200px) {

}