.projects_work {
    display: flex;
    flex-direction: column;
    padding: 5rem 0 1rem 0;
}

.projects_work-head {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-align: center;
}

.projects_work-p {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: -0.5px;
    text-align: center;
    line-height: 2rem;
}

.projects_work-filter {
    display: flex;
    justify-content: center;
    list-style: none;
    align-items: center;
}

.projects_work-filter .active {
    background: #000;
    color: #fff;
    padding: 6px;
    border-radius: 8px;
}

.projects_work-filter li {
    margin: 2rem 2rem 0 2rem;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
}

.projects_work-thumb {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem;
    row-gap: 3rem;
    padding: 3rem 0;
}

.projects_work-thumb-item {
    align-self: center;
    justify-self: center;
    max-width: 700px;
}

.projects_work-thumb img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 0.5rem;
}

.projects_work-thumb-item-divp {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.projects_work-thumb-title-p {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5em;
    letter-spacing: -0.5px;
}

.projects_work-thumb-cat-p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5em;
    letter-spacing: 0px;
}

.projects_work-thumb-link-p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5em;
    cursor: pointer;
}

@media screen and (max-width: 576px) {
    
}

@media screen and (max-width: 768px) {
    .projects_work-thumb {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 992px) {
    .projects_work-thumb {
        gap: 3rem;
        padding: 3rem 2rem;
    }
    .projects_work-head {
        padding: 0rem 2rem;
    }
    .projects_work-p {
        padding: 0rem 2rem;
    }
    .projects_work-filter li {
        margin: 2rem 1rem 0 1rem;
    }
}

@media screen and (max-width: 1200px) {

}