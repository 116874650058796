.project4_content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.project4_content img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.project4_content-summary {
    padding: 2rem 0;
}

@media screen and (max-width: 576px) {
    
}

@media screen and (max-width: 768px) {
    
}

@media screen and (max-width: 992px) {
    .project4_content {
        padding: 0 2rem;
    }
}

@media screen and (max-width: 1200px) {

}