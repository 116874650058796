.cont_header {
    display: flex;
    align-items: center;
    padding: 5rem 0;
}

.cont_header-content {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.cont_header-content-head {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-align: center;
}

.cont_header-content-p {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: -0.5px;
    text-align: center;
    line-height: 2rem;
}

.cont_header-content-social {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 28px;
    padding: 1rem 0;
}

.cont_header-content-social span {
    margin: 0 1rem;
}

.ityped-cursor {
    opacity: 1;
    -webkit-animation: blink 0.3s infinite;
    -moz-animation: blink 0.3s infinite;
    animation: blink 0.3s infinite;
    animation-direction: alternate;
}
 
@keyframes blink {
    100% {
        opacity: 0;
    }
}
 
@-webkit-keyframes blink {
    100% {
        opacity: 0;
    }
}
 
@-moz-keyframes blink {
    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 576px) {
    
}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 992px) {
    .cont_header {
        padding: 5rem 2rem;
    }
}

@media screen and (max-width: 1200px) {

}