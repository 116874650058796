@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;400;500;600;700&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    font-family: 'Karla',sans-serif;
}

a {
    text-decoration: none;
    color: unset;
}